import './App.css';
import React from 'react';
import MyTable from './MyTable';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header> */}
      <MyTable />
    </div>
  );
}

export default App;
