import React, { useMemo } from 'react';
import Papa from 'papaparse';

import { useTable, useSortBy } from 'react-table';

function MyTable() {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Make',
        accessor: 'Make',
      },
      {
        Header: 'Model',
        accessor: 'Model',
      },
      {
        Header: 'Year',
        accessor: 'Year',
      },
      {
        Header: 'Range (km)',
        accessor: 'Range (km)',
      },
      {
        Header: 'Capacity (kWh)',
        accessor: 'Capacity (kWh)',
      },
      {
        Header: 'Efficiency',
        accessor: 'Efficiency',
      },
      {
        Header: 'CAD / M',
        accessor: 'CAD / M',
      },
      {
        Header: 'CAD / Y',
        accessor: 'CAD / Y',
      },
      {
        Header: 'Price (CAD)',
        accessor: 'Price (CAD)',
      },
      {
        Header: '- Subsidy',
        accessor: '- Subsidy',
      },
      {
        Header: '- Dwnpmnt',
        accessor: '- Dwnpmnt',
      },
      {
        Header: 'Financing int',
        accessor: 'Financing int',
      },
      {
        Header: 'Term',
        accessor: 'Term',
      },
      {
        Header: 'CAD / M',
        accessor: 'CAD / M2',
      },
      {
        Header: 'TAX / M',
        accessor: 'TAX / M2',
      },
      {
        Header: 'CAD / Y',
        accessor: 'CAD / Y2',
      },
    ],
    []
  );

  const csvString = `Make,Model,Year,Range (km),Capacity (kWh),,Efficiency,CAD / M,CAD / Y,,Price (CAD),- Subsidy,- Dwnpmnt,Financing int,,Term,CAD / M2,TAX / M2,CAD / Y2,
  Tesla,Model 3,,405.00,75.00,,185.19,$23.15,$277.79,,"$54,990.00","$45,990.00","$28,990.00",6.45%,,96,-$387.35,-$433.84,"-$4,648.24",
  ,Model Y,,394.00,60.00,,152.28,$19.04,$228.42,,"$61,990.00","$52,990.00","$35,990.00",6.45%,,84,-$533.56,-$597.59,"-$6,402.73",
  Toyota,BZ4H,,406.00,71.40,,175.86,$21.98,$263.79,,"$53,035.00","$44,035.00","$27,035.00",7.39%,,84,-$413.20,-$462.79,"-$4,958.45",
  Hyndai,Ionic 5,,487.60,77.40,,158.74,$19.84,$238.11,,"$51,643.00","$42,643.00","$25,643.00",6.45%,,84,-$380.16,-$425.78,"-$4,561.96",
  Kia,EV6,,498.90,77.40,,155.14,$19.39,$232.71,,"$50,544.00","$41,544.00","$24,544.00",6.49%,,84,-$364.35,-$408.07,"-$4,372.15",
  ,Niro EV,,426.00,68.00,,159.62,$19.95,$239.43,,"$52,346.00","$43,346.00","$26,346.00",6.49%,,84,-$391.10,-$438.03,"-$4,693.15",
  Ford,Mach-E,,502.12,91.00,,181.23,$22.65,$271.85,,"$56,995.00","$47,995.00","$30,995.00",7.00%,,84,-$467.80,-$523.93,"-$5,613.57",
  VW,ID.4,,336.00,62.00,,184.52,$23.07,$276.78,,"$46,495.00","$37,495.00","$20,495.00",7.00%,,84,-$309.32,-$346.44,"-$3,711.89",
  Chevrolet,Bolt,,397.67,66.00,,165.97,$20.75,$248.96,,"$43,147.00","$34,147.00","$17,147.00",5.49%,,84,-$246.32,-$275.88,"-$2,955.86",
  Subaru,Soltera AWD,,366.93,72.80,,198.4,$24.80,$297.60,,"$56,805.00","$47,805.00","$30,805.00",5.99%,,60,-$595.40,-$666.85,"-$7,144.84",`;
  
    const data = useMemo(() => {
      const parsed = Papa.parse(csvString, { header: true, skipEmptyLines: true });
      return parsed.data;
    }, [csvString]);
  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default MyTable;
